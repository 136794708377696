import React from "react";
import history from "../../utils/history";

import ValidateEmailContainer from "../../containers/ValidateEmailContainer";

const ValidateEmailPage = () => {
    const {
        location: { pathname },
    } = history;

    return <ValidateEmailContainer path={pathname} />;
};

export default ValidateEmailPage;
