import {createReducer} from "@reduxjs/toolkit";
import {addUser, RecentReports, removeUser, updateUser, User} from "./actions";

export interface UserState {
    user: User;
}

const initialState: UserState = {
    user: {} as User
}

export default createReducer(initialState,  ((builder) => {
    builder
        .addCase(addUser, (state, action) => {
            const userData = action.payload;

            state.user = userData;
            localStorage.setItem('user', JSON.stringify(userData));
            localStorage.setItem('token', userData.token);
            localStorage.setItem('refresh_token', userData.refresh_token);
        })
        .addCase(removeUser, (state, action) => {
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');

            state.user = {} as User;
        })

}))