/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import ReactGA from "react-ga";
import {Modal} from "antd";
import "./style.css";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import LoginDialog from "../LoginDialog/LoginDialog";
import RegisterDialog from "../RegisterDialog/RegisterDialog";
import * as api from "../../utils/api-client";
import store from "../../state";
import {useAddUser, useDisconnectUser} from "../../state/user/hooks";
import ForgotPasswordDialog from "../ForgotPasswordDialog/ForgotPasswordDialog";
import {toast} from "react-toastify";
import {environment} from "../../environment";

ReactGA.initialize("G-GS57DV480Q");
ReactGA.pageview(window.location.pathname + window.location.search);
export default () => {
    const [isDisconnectModalOpen, setDisconnectModalOpen] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
    const [isRegisterDialogOpen, setIsRegisterDialogOpen] = useState(false);
    const [isForgotPasswordDialogOpen, setIsForgotPasswordDialogOpen] = useState(false);

    const [user, setUser] = useState(store.getState().user.user);
    const [isUserConnected, setIsUserConnected] = useState(false);

    const addUser = useAddUser();
    const removeUser = useDisconnectUser();
    const location = useLocation();

    useEffect(() => {
        const locationCode = Number(location.search.substring(4, location.search.length));

        if (locationCode === 50001 || locationCode === 10006) {
            removeUser();
            setIsUserConnected(false);
            setIsLoginDialogOpen(true);
        }

        if (!localStorage.getItem('token')) {
            removeUser();
            setIsUserConnected(false);
        }
    }, [location])

    useEffect(() => {
        store.subscribe((storeListener) => {
            setUser(store.getState().user.user);
        });

        const storedUser = localStorage.getItem("user");

        if (storedUser) {
            addUser(JSON.parse(storedUser));
            setIsUserConnected(true);
        }
    }, [])

    useEffect(() => {
        if (Object.keys(user).length > 0) {
            setIsUserConnected(true);
        } else {
            setIsUserConnected(false);
        }
    }, [user])

    const closeLoginModal = () => {
        setIsLoginDialogOpen(false);
    }

    const onLogin = (response) => {
        if (!response) {
            setIsLoginDialogOpen(false);
            return;
        }

        if (response.email && response.password) {
            api.post('api/login_check', response).then((result) => {

                if (result && result.code && result.code === 401) {
                    toast.error(result.message);
                    return;
                }

                localStorage.setItem('token', result.token)
                localStorage.setItem('refresh-token', result.refresh_token);

                setIsLoginDialogOpen(false);
                api.post('users/get-user').then((addUserResult) => {
                    addUser(addUserResult.data);
                })
            }).catch((err) => {
                console.log(err);
                setIsLoginDialogOpen(false);
                toast.error(err.message, {position: 'bottom-right'});
            });
        }
    }

    const onRegister = (response) => {
        if (!response) {
            setIsRegisterDialogOpen(false);
            return;
        }

        api.post('users/register', response).then((result) => {
            setIsRegisterDialogOpen(false);
            if (result.status.code === 10001) {
                toast.error(result.status.message, {position: 'bottom-right'});
            } else {
                toast.success('User registered successfully, please verify your email address', {
                    position: 'bottom-right'
                })
            }
        }).catch((err) => {
            setIsRegisterDialogOpen(false);
            toast.error(err, {
                position: 'bottom-right'
            });
        })
    }

    const onForgotPassword = (response) => {
        if (!response) {
            setIsForgotPasswordDialogOpen(false);
            return;
        }

        api.post('reset-password', response).then((result) => {
            setIsForgotPasswordDialogOpen(false);
            toast.success('Please check your email to continue!')
        }).catch((err) => {
            setIsForgotPasswordDialogOpen(false);
            if (err.status?.code === 10004) {
                toast.error(err.status.message);
            }
        })
    }

    const handleLoginDialogOpen = () => {
        setIsRegisterDialogOpen(false);
        setIsLoginDialogOpen(true);
    }

    const handleOpenRegister = () => {
        setIsLoginDialogOpen(false);
        setIsRegisterDialogOpen(true);
    }

    const handleOpenForgotPassword = () => {
        setIsLoginDialogOpen(false);
        setIsForgotPasswordDialogOpen(true);
    }

    const handleDisconnectModalOpen = () => {
        setDisconnectModalOpen(true);
    };
    //
    const handleDisconnectModalClose = () => {
        setDisconnectModalOpen(false);
    };

    const onDisconnectOk = () => {
        removeUser();
        setIsUserConnected(false);
        handleDisconnectModalClose();
    };

    const onDisconnectCancel = () => {
        handleDisconnectModalClose();
    };

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    return (
        <>
            <header className="page-header">
                {/* <!-- Horizontal Menu Start--> */}
                <nav className="main-menu static-top navbar-dark navbar navbar-expand-lg fixed-top mb-1">
                    <div className="container">
                        <Link
                            className="navbar-brand animated"
                            data-animation="fadeInDown"
                            data-animation-delay="0.5s"
                            to={"/"}
                        >
                            <img
                                src="/theme-assets/images/header-logo-csd.png"
                                style={{maxHeight: "35px"}}
                                alt="CSD Logo"
                            />
                            <span className="brand-text" style={{fontSize: "16px"}}>
                <span className="font-weight-bold">Crypto</span> Scam Defence
              </span>
                        </Link>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarCollapse"
                            aria-controls="navbarCollapse"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={() => toggleMobileMenu()}
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div
                            className={
                                mobileMenuOpen
                                    ? "collapse navbar-collapse show"
                                    : "collapse navbar-collapse"
                            }
                            id="navbarCollapse"
                        >
                            <div
                                id="navigation"
                                className="navbar-nav ml-auto"
                                style={{paddingTop: "3px"}}
                            >
                                <ul className="navbar-nav mt-1">
                                    <li
                                        className="dropdown show mr-2 px-2 animated"
                                        data-animation="fadeInDown"
                                        data-animation-delay="1.6s"
                                    >
                                        <Link
                                            className="dropdown-toggle white"
                                            to="/#"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            What is CSD
                                        </Link>

                                        <div className="dropdown-menu">
                                            <a
                                                className="dropdown-item nav-link"
                                                href="/#about"
                                                onClick={() => toggleMobileMenu()}
                                            >
                                                About
                                            </a>
                                            <a
                                                className="dropdown-item nav-link"
                                                href="/#whitepaper"
                                                onClick={() => toggleMobileMenu()}
                                            >
                                                Whitepaper
                                            </a>
                                            <a
                                                className="dropdown-item nav-link"
                                                href="/#token-distribution"
                                                onClick={() => toggleMobileMenu()}
                                            >
                                                Tokenomics
                                            </a>
                                            <a
                                                className="dropdown-item nav-link"
                                                href="/#roadmap"
                                                onClick={() => toggleMobileMenu()}
                                            >
                                                Roadmap
                                            </a>
                                        </div>
                                    </li>
                                    <li
                                        className="nav-item animated"
                                        data-animation="fadeInDown"
                                        data-animation-delay="1.2s"
                                    >
                                        <a
                                            className="nav-link"
                                            href="/#problem-solution"
                                            onClick={() => toggleMobileMenu()}
                                        >
                                            Solutions
                                        </a>
                                    </li>

                                    <li
                                        className="nav-item animated"
                                        data-animation="fadeInDown"
                                        data-animation-delay="0.5s"
                                    >
                                        <a
                                            className="nav-link"
                                            href="/news"
                                            onClick={() => toggleMobileMenu()}
                                        >
                                            News
                                        </a>
                                    </li>
                                    <li
                                        className="dropdown show mr-2 px-2 animated"
                                        data-animation="fadeInDown"
                                        data-animation-delay="1.6s"
                                    >
                                        <Link
                                            className="dropdown-toggle white"
                                            to="/#"
                                            role="button"
                                            id="more"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            More
                                        </Link>
                                        <div className="dropdown-menu" aria-labelledby="more">
                                            {/*<Link*/}
                                            {/*    className="dropdown-item"*/}
                                            {/*    to="/lock"*/}
                                            {/*    onClick={() => toggleMobileMenu()}*/}
                                            {/*>*/}
                                            {/*    CSD Lock*/}
                                            {/*</Link>*/}
                                            <Link
                                                className="dropdown-item"
                                                to="/#"
                                                onClick={() => toggleMobileMenu()}
                                            >
                                                Bridge (soon)
                                            </Link>
                                            <Link
                                                className="dropdown-item"
                                                to="#"
                                                onClick={() => toggleMobileMenu()}
                                            >
                                                Community Voice (soon)
                                            </Link>
                                            <Link
                                                className="dropdown-item"
                                                to="/contact"
                                                onClick={() => toggleMobileMenu()}
                                            >
                                                Contact
                                            </Link>
                                        </div>
                                    </li>
                                </ul>
                                <span id="slide-line"></span>
                                <form className="form-inline mt-2 mt-md-0 d-flex flex-nowrap">
                                    {user && Object.keys(user).length > 0 ?
                                        <>
                                            <li className="white font-small mr-1">
                                                <a
                                                    className="d-flex align-items-center justify-content-center w-25 h-25 rounded-circle btn btn-sm btn-gradient-purple btn-glow my-2 my-sm-0 animated"
                                                    data-animation="fadeInDown"
                                                    data-animation-delay="1.8s"
                                                    target="_self"
                                                >{user.first_name?.substring(0, 1) || 'N'}{user.last_name?.substring(0, 1) || 'A'}</a>
                                            </li>

                                            <div style={{display: "flex", alignItems: "center", marginRight: "1rem"}}>
                                                <a
                                                    className="btn btn-sm btn-gradient-purple btn-glow my-2 my-sm-0 animated"
                                                    data-animation="fadeInDown"
                                                    href={`${environment.app_url}/dashboard/`}
                                                >
                                                    Dashboard
                                                </a>
                                            </div>
                                        </>
                                        :
                                        <a className="text-white btn btn-sm btn-gradient-purple btn-glow my-2 mx-1 my-sm-0 animated white"
                                           data-animation="fadeInDown"
                                           data-animation-delay="1.8s"
                                           onClick={handleLoginDialogOpen}
                                           target="_self">
                                            Login
                                        </a>
                                    }
                                    {isUserConnected && <a
                                        className="btn btn-sm m-md-0 ml-2 btn-gradient-purple btn-glow pl-2 my-2 my-sm-0 animated"
                                        data-animation="fadeInDown"
                                        data-animation-delay="1.8s"
                                        onClick={handleDisconnectModalOpen}
                                        target="_self"
                                        style={{
                                            marginLeft: "20px !important",
                                            color: "white",
                                            padding: "5px 8px  0px  8px",
                                        }}
                                    >
                                        <PowerSettingsNewIcon/>
                                    </a>
                                    }

                                </form>
                            </div>
                        </div>
                    </div>
                </nav>
                {/* <!-- /Horizontal Menu End--> */}
            </header>
            <Modal
                title="Confirm disconenct"
                open={isDisconnectModalOpen}
                onOk={onDisconnectOk}
                onCancel={onDisconnectCancel}
                okText="Yes"
                cancelText="No"
            >
                <p>Do you really want to log out?</p>
            </Modal>

            <LoginDialog open={isLoginDialogOpen}
                         handleLoginDialogClose={onLogin}
                         handleRegister={handleOpenRegister}
                         handleForgotPassword={handleOpenForgotPassword}
                         closeLoginModal={closeLoginModal}
            />
            <RegisterDialog open={isRegisterDialogOpen} handleRegisterDialogClose={onRegister}
                            handleLogin={handleLoginDialogOpen}/>
            <ForgotPasswordDialog open={isForgotPasswordDialogOpen} handleForgotPasswordDialogClose={onForgotPassword} handleLogin={onLogin}/>
        </>
    );

};
