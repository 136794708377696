export const passwordValidator = (value: string): string | undefined => {
    if (!value) {
        return 'Password cannot be empty';
    }

    if (value?.length < 8) {
        return 'Password cannot be shorter than 8 characters'
    }
}

export const emailValidator = (value: string): string | undefined => {
    if (!value) {
        return 'Email cannot be empty';
    }

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(value)) {
        return 'Provided email must be a valid email address';
    }
}