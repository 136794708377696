import React from "react";
import { useParams } from 'react-router-dom';
import CryptoNewsDetailComponent from "../../components/CryptoNewsDetailComponent";

function NewsDetailPage() {
  const params = useParams();
  return <CryptoNewsDetailComponent {...params} />
}

export default NewsDetailPage;
