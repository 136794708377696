import {useDispatch} from "react-redux";
import {removeUser, addUser, User} from "./actions";
import {useCallback} from "react";

export function useAddUser(): (user: User) => void {
    const dispatch = useDispatch();

    return useCallback((user: User) => dispatch(addUser(user)), [dispatch])
}

export function useDisconnectUser(): () => void {
    const dispatch = useDispatch();

    return useCallback(() => dispatch(removeUser()), [dispatch]);
}

