import { Button, Card, Dialog, TextField } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { emailValidator, passwordValidator } from "./RegisterValidators";
import lock from "../../theme/theme-assets/images/svg/lock-closed.svg";

interface UserRegister {
    email: string;
    password: string;
    confirmPassword: string; // New field for confirmPassword
    refCode: string;
}
interface UserRegisterData {
    email: string;
    password: string;
    refCode: string;
}

interface RegisterDialogProps {
    open: boolean;
    handleRegisterDialogClose: (response?: UserRegisterData) => void;
    handleLogin: () => void;
}

const RegisterDialog = (props: RegisterDialogProps) => {
    const [userRegister, setUserRegister] = useState<UserRegister>({
        email: '',
        password: '',
        confirmPassword: '', // Initialize confirmPassword
        refCode: ''
    } as UserRegister);
    const [formValid, setFormValid] = useState({email: false, password: false, confirmPassword: false, refCode: true});
    const [formErrors, setFormErrors] = useState({email: '', password: '', confirmPassword: ''});

    const validators: any = {
        password: (password: string) => passwordValidator(password),
        email: (email: string) => emailValidator(email),
        confirmPassword: (confirmPassword: string) => confirmPassword === userRegister.password ? '' : 'Passwords do not match'
    }

    const runValidators = (propertyName: string, value: string) => {
        if (propertyName === 'confirmPassword') {
            value = userRegister.password === value ? value : '';
        }
        const errors = validators[propertyName] ? validators[propertyName](value) : '';

        setFormErrors((prevFormErrors: any) => ({
            ...prevFormErrors,
            [propertyName]: errors
        }));

        setFormValid((prevFormValid) => ({
            ...prevFormValid,
            [propertyName]: !errors
        }));
    }

    const handleInputChange = (event: any) => {
        const {name, value} = event.target;
        setUserRegister((prevUser) => ({
            ...prevUser,
            [name]: value
        }));
        if(name === 'password' || name === 'confirmPassword') {
            runValidators('confirmPassword', userRegister.confirmPassword);
        }
        runValidators(name, value);
    }

    const handleClose = () => {
        if (Object.values(formValid).some((value: boolean) => !value)) {
            return;
        }
        // Since confirmPassword isn't needed outside, don't send it
        const { confirmPassword, ...userDetails } = userRegister;
        setUserRegister({email: '', password: '', confirmPassword: '', refCode: ''}) // Reset confirmPassword here as well
        props.handleRegisterDialogClose(userDetails);
    }

    const close = () => {
        props.handleRegisterDialogClose();
    }

    return (
        <Dialog sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: '400px' }, overflowX: "hidden"}} onClose={close} open={props.open}>
            <Card sx={{ padding: "2rem", backgroundColor: "#140e3800"  }}>
                <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
                    <Link to="/">
                        <img src={lock} alt="Logo" style={{ width: '100px' }} />
                    </Link>
                    <p className="text-center h5 text-capitalize">Register</p>
                </div>
                <TextField id="email" label="Email address" name="email" variant="outlined" fullWidth margin="normal" value={userRegister.email} error={!!formErrors.email} onChange={handleInputChange} helperText={formErrors.email} />
                <TextField id="password" label="Password" name="password" variant="outlined" fullWidth margin="normal" type="password" value={userRegister.password} error={!!formErrors.password} onChange={handleInputChange} helperText={formErrors.password} />
                <TextField id="confirmPassword" label="Confirm Password" name="confirmPassword" variant="outlined" fullWidth margin="normal" type="password" value={userRegister.confirmPassword} error={!!formErrors.confirmPassword} onChange={handleInputChange} helperText={formErrors.confirmPassword} />
                <TextField id="refCode" label="Referral code (optional)" name="refCode" variant="outlined" fullWidth margin="normal" value={userRegister.refCode} onChange={handleInputChange} />
                <Button onClick={handleClose} variant="contained" color="primary" fullWidth style={{ marginTop: '1rem' }}>
                    Submit
                </Button>
            </Card>
        </Dialog>
    );
}

export default RegisterDialog;
