import React, {useState} from "react";
import {Button, TextField} from "@mui/material";
import * as api from "../../utils/api-client";
import {toast} from "react-toastify";
import {emailValidator, passwordValidator} from "../../components/RegisterDialog/RegisterValidators";
import styled from "styled-components";
import {environment} from "../../environment";

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'white',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'white !important',
        },
        '&:hover fieldset': {
            borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'white',
        },
        '& input': {
            color: 'white',
        },
        '& label': {
            color: 'white',
        }
    },
});

const ResetPasswordContainer = (props) => {
    const [password, setPassword] = useState('');
    const [retypePassword, setRetypePassword] = useState('');
    const [showPasswordFields, setShowPasswordFields] = useState(true);

    const getPathToken = (path) => {
        const parts = path.split('/');
        return parts.pop();
    }
    const token = getPathToken(props.path);
    const [formValid, setFormValid] = useState({password: false});
    const [formErrors, setFormErrors] = useState({password: ''});
    const validators = {
        password: (password) => passwordValidator(password),
        retypePassword: (retypePassword) => password === retypePassword ? '' : 'Passwords do not match'
    }

    const runValidators = (propertyName, value) => {
        const errors = validators[propertyName] ? validators[propertyName](value) : '';

        setFormErrors((prevFormErrors) => ({
            ...prevFormErrors,
            [propertyName]: errors,
        }));

        setFormValid((prevFormValid) => ({
            ...prevFormValid,
            [propertyName]: !errors,
        }));
    }


    const handleInputChange = (event) => {
        const {name, value} = event.target;

        if (name === "password") {
            setPassword(value);
            // Also validate retypePassword when updating password
            runValidators("retypePassword", retypePassword);
        } else if (name === "retypePassword") {
            setRetypePassword(value);
        }

        runValidators(name, value);
    }

    const onSubmit = () => {
        if (Object.values(formValid).every((value) => value)) {
            const objToSend = {
                token,
                password
            }

            api.post('reset-password/validate', objToSend).then((result) => {
                setShowPasswordFields(false);
                toast.success(result?.status?.message, {onClose: () => {
                        window.location.href = `${environment.app_url}`;
                    }})
            }).catch((err) => {
                toast.error(err.errors.message);
            });
        } else {
            // Run validators to show errors
            runValidators('password', password);
            runValidators('retypePassword', retypePassword);
        }
    }

    return (
        <main>
            <section id="contact" className="contact section-padding">
                <div className="container">
                    {showPasswordFields && (
                        <>
                            <div className={"row d-flex justify-content-center align-items-center mt-5"}>
                                <h3 className={"white"}>Enter your new password</h3>
                            </div>
                            <div className="d-flex justify-content-center align-items-center mt-5">

                                <CssTextField
                                    onInput={handleInputChange}
                                    value={password} name="password"
                                    type="password"
                                    variant="outlined"
                                    error={!!formErrors.password}
                                    helperText={formErrors.password || ''}
                                    label="Password" // Add label
                                    InputLabelProps={{style: {color: 'white', border: 'white'}}}
                                    sx={{m: 2, width: '50ch'}}
                                />
                            </div>
                            <div className="d-flex justify-content-center align-items-center mt-3">
                                <CssTextField
                                    className="white"
                                    onInput={handleInputChange}
                                    value={retypePassword}
                                    name="retypePassword"
                                    type="password"
                                    variant="outlined"
                                    error={!!formErrors.retypePassword}
                                    helperText={formErrors.retypePassword || ''}
                                    label="Retype Password"
                                    InputLabelProps={{style: {color: 'white'}}}
                                    sx={{m: 2, width: '50ch'}}
                                />
                            </div>
                            <div className="d-flex justify-content-center mb-3">
                                <Button onClick={onSubmit}
                                        style={{
                                            color: "white",
                                            backgroundColor: "#A14BCF",
                                            width: "50%",
                                            marginTop: "2rem"
                                        }}>
                                    Submit
                                </Button>
                            </div>
                        </>
                    )}


                </div>
            </section>
        </main>
    );
};

export default ResetPasswordContainer;
