import React, {useState} from "react";
import {Button, Card, Dialog, TextField} from "@mui/material";
import {Link} from "react-router-dom";
import {emailValidator} from "../ForgotPasswordDialog/ForgotPasswordValidators";
import lock from "../../theme/theme-assets/images/svg/lock-closed.svg";
import CircularProgress from "@mui/material/CircularProgress";

interface UserForgotPassword {
    email: string;
}

interface ForgotPasswordProps {
    open: boolean;
    handleForgotPasswordDialogClose: (userForgotPassword?: UserForgotPassword) => void;
    handleLogin: () => void;
}

const ForgotPasswordDialog = (props: ForgotPasswordProps) => {
    const [userForgotPassword, setUserForgotPassword] = useState<UserForgotPassword>({email: ""});
    const [loading, setLoading] = useState(false); // Loading state
    const [formValid, setFormValid] = useState({email: false});
    const [formErrors, setFormErrors] = useState({email: ''});

    const validators: any = {
        email: (password: string) => emailValidator(password)
    }

    const runValidators = (propertyName: string, value: string) => {
        const errors = validators[propertyName] ? validators[propertyName](value) : '';

        setFormErrors((prevFormErrors: any) => {
            if (errors) {
                return {...prevFormErrors, [propertyName]: errors};
            } else {
                return {...prevFormErrors, [propertyName]: ''};
            }
        });

        setFormValid((prevFormValid) => {
            if (errors) {
                return {...prevFormValid, [propertyName]: false}
            } else {
                return {...prevFormValid, [propertyName]: true}
            }
        })
    }

    const handleInputChange = (event: any) => {
        const {name, value} = event.target;

        runValidators(name, value);

        setUserForgotPassword((prevValue) => {
            return {...prevValue, [name]: value}
        });
    }

    const handleClose = () => {
        if (Object.values(formValid).some((value: boolean) => !value)) {

            Object.keys(userForgotPassword).forEach((userKey: string) => {
                runValidators(userKey, userForgotPassword[userKey as keyof UserForgotPassword]);

            });

            return;
        }
        setLoading(true);
        props.handleForgotPasswordDialogClose(userForgotPassword);
        setLoading(false);
    }

    const close = () => {
        props.handleForgotPasswordDialogClose();
    }

    return (
        <>
            <Dialog sx={{minWidth: ['100%', null, null, '1000px'], padding: "2rem"}} fullWidth={true}
                    onClose={(event, reason) => {
                        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                            close(); // Your close function
                        }
                    }}
                    open={props.open} >

                <Card sx={{padding: "2rem", display: "flex", width: "100%"}}>
                    <div className="w-50 mr-4 d-none d-md-flex justify-content-center align-items-center">
                        <div className="d-flex align-items-center justify-content-center w-100 h-100"
                             style={{background: "linear-gradient( 40deg, #bf68e6 20%, #9e48cd 51%, #bf68e6 90% )"}}>
                            <Link
                                className="navbar-brand animated"
                                data-animation="fadeInDown"
                                data-animation-delay="1s"
                                to={"/"}
                            >
                                <img
                                    src={lock}
                                    alt="CSD Logo"
                                />
                            </Link>
                        </div>
                    </div>

                    <div className="w-100 d-flex" style={{flexDirection: "column"}}>
                        <div className="w-100 d-sm-flex d-md-none justify-content-center">
                            <div className="mb-2"
                                 style={{background: "linear-gradient( 40deg, #bf68e6 20%, #9e48cd 51%, #bf68e6 90% )"}}>
                                <Link
                                    className="navbar-brand animated d-flex justify-content-center"
                                    data-animation="fadeInDown"
                                    data-animation-delay="1s"
                                    to={"/"}
                                >
                                    <img
                                        src={lock}
                                        alt="CSD Logo"
                                    />
                                </Link>
                            </div>
                        </div>
                        <p className="text-center h5 text-capitalize">Forgot password</p>
                        <div className="d-flex" style={{flexDirection: "column"}}>
                            <TextField id="email"
                                       error={!!formErrors.email} helperText={formErrors.email}
                                       label="Your email address" name="email" variant="standard"
                                       value={userForgotPassword.email} sx={{marginTop: 2}}
                                       onChange={handleInputChange}/>
                        </div>
                        <div className="mt-4">
                            {loading ? (
                                <CircularProgress style={{color: "#A14BCF"}}/>
                            ) : (
                                <Button onClick={handleClose}
                                        style={{color: "white", backgroundColor: "#A14BCF", width: "100%"}}>
                                    Reset
                                </Button>
                            )}
                        </div>
                    </div>
                </Card>
            </Dialog>
        </>
    )
}

export default ForgotPasswordDialog;