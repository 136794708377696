import React from "react";
import history from "../../utils/history";

import ResetPasswordContainer from "../../containers/ResetPasswordContainer";

const ResetPasswordPage = () => {
    const {
        location: { pathname },
    } = history;

    return <ResetPasswordContainer path={pathname} />;
};

export default ResetPasswordPage;
