import React, { useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import SocialShare from "../SocialShareButtons/SocialShare";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNewsById } from "../../state/news/newsSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const socialItems = ['Twitter', 'Reddit', 'Telegram', 'Facebook', 'Linkedin', 'Whatsapp'];

export default (params) => {
  const { id } = params;
  const dispatch = useDispatch();
  const newsItemIsLoading = useSelector((state) => state.news.newsItemIsLoading);
  const currentNewsItem = useSelector((state) => state.news.currentNewsItem);

  useEffect(() => {
    dispatch(getNewsById(id))
  }, [])

  return (
    <main>
      {
        currentNewsItem ?
            <section className="blog-area" id="head-area">
              <div className="blog-head" data-midnight="white">
                <div className="blog-head-content">
                  <img src={currentNewsItem.image_url} alt="Blog 1" />
                </div>
              </div>
              <div className="container">
                <div className="blog-container">
                  <div className="row">
                    <div className="col-md-12 col-lg-12">
                      <div className="breadcrumb">
                        <Link to={"/"}> Home </Link> / <Link to={"/news"}>News</Link> / <a className="current">Detail Page</a>
                      </div>
                      <div className="card square">
                        <div className="card-body">
                          <div className="content-area">
                            <div>
                              <SocialShare socials={socialItems} url={window.location.href}/>
                            </div>
                            <h2 className="card-title">{currentNewsItem.title}</h2>
                            <h6 className="subtitle">
                              { ReactHtmlParser(currentNewsItem.summary) }
                            </h6>
                            { ReactHtmlParser(currentNewsItem.content) }
                            <p className="text-uppercase mt-5">
                              POSTED <strong>{currentNewsItem.posted_date}</strong> | BY{" "}
                              <strong>{currentNewsItem.author}</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            :
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={newsItemIsLoading}
            >
            <CircularProgress color="inherit" />
            </Backdrop>
      }
    </main>
  );
};
