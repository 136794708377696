import React, {useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import * as api from "../../utils/api-client";
import { toast } from "react-toastify";

const ValidateEmailContainer = () => {
    const location = useLocation(); // Use useLocation hook to access the current location object
    const [verificationStatus, setVerificationStatus] = useState('Verifying email, please wait...');

    const parseQueryParams = (search) => {
        return new URLSearchParams(search);
    };

    useEffect(() => {
        const queryParams = parseQueryParams(location.search);

        const expires = queryParams.get('expires');
        const id = queryParams.get('id');
        const signature = queryParams.get('signature');
        const token = queryParams.get('token');

        const dataToSend = {
            expires,
            id,
            signature,
            token
        };
        api.post('validate-email', dataToSend)
            .then(response => {
                setVerificationStatus('Email verified successfully, proceed to login!');
                toast.success('Email verified successfully, proceed to login!');
            })
            .catch(error => {
                toast.error('Failed to verify email.');
            });
    }, []);

    return (
        <main>
            <section id="validate-email" className="section-padding">
                <div className={"container justify-content-center"}>
                    <div className={"row d-flex justify-content-center align-items-center mt-5"}>
                        <h5 className={"text-white"}>{verificationStatus}</h5>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default ValidateEmailContainer;
