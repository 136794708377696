import {Button, Card, CircularProgress, Dialog, TextField} from "@mui/material";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {emailValidator, passwordValidator} from "./LoginValidators";
import lock from '../../theme/theme-assets/images/svg/lock-closed.svg'

interface UserLogin {
    email: string;
    password: string;
}

interface LoginDialogProps {
    open: boolean;
    handleLoginDialogClose: (response?: UserLogin) => void;
    handleRegister: () => void;
    handleForgotPassword: () => void;
    closeLoginModal: () => void;
}

const LoginDialog = (props: LoginDialogProps) => {
    const [userLogin, setUserLogin] = useState<UserLogin>({email: '', password: ''} as UserLogin);
    const [formValid, setFormValid] = useState({email: false, password: false});
    const [formErrors, setFormErrors] = useState({email: '', password: ''});
    const [isLoading, setIsLoading] = useState(false);

    const validators: any = {
        password: (password: string) => passwordValidator(password),
        email: (email: string) => emailValidator(email)
    }

    const runValidators = (propertyName: string, value: string) => {
        const errors = validators[propertyName] ? validators[propertyName](value) : '';

        setFormErrors((prevFormErrors: any) => {
            if (errors) {
                return {...prevFormErrors, [propertyName]: errors};
            } else {
                return {...prevFormErrors, [propertyName]: ''};
            }
        });

        setFormValid((prevFormValid) => {
            if (errors) {
                return {...prevFormValid, [propertyName]: false}
            } else {
                return {...prevFormValid, [propertyName]: true}
            }
        })
    }


    const handleInputChange = (event: any) => {
        const {name, value} = event.target;

        runValidators(name, value);

        setUserLogin((prevUser) => {
            return {
                ...prevUser,
                [name]: value
            }
        })
    }

    const loginAction = () => {
        if (Object.values(formValid).some((value: boolean) => !value)) {
            Object.keys(userLogin).forEach((userKey: string) => {
                runValidators(userKey, userLogin[userKey as keyof UserLogin]);
            });

            return;
        }
        setIsLoading(true);
        setUserLogin({email: '', password: ''} as UserLogin)
        props.handleLoginDialogClose(userLogin);
    }

    const handleClose = () => {
        setIsLoading(false)
        props.closeLoginModal();
    }

    const close = () => {
        props.closeLoginModal();
        setIsLoading(false);
    }

    return (
        <div style={{width: "100%"}}>
            <Dialog
                sx={{minWidth: ['100%', null, null, '1000px'], padding: "2rem"}}
                fullWidth={true}
                onBackdropClick={close}
                onClose={handleClose}
                open={props.open}>
                <Card sx={{padding: "2rem", display: "flex", width: "100%"}}>
                    <div className="w-50 mr-4 d-none d-md-flex justify-content-center align-items-center">
                        <div className="d-flex align-items-center justify-content-center w-100 h-100"
                             style={{background: "linear-gradient( 40deg, #bf68e6 20%, #9e48cd 51%, #bf68e6 90% )"}}>
                            <Link
                                className="navbar-brand animated"
                                data-animation="fadeInDown"
                                data-animation-delay="1s"
                                to={"/"}
                            >
                                <img
                                    src={lock}
                                    alt="CSD Logo"
                                />
                            </Link>
                        </div>
                    </div>

                    <div className="w-100 d-flex" style={{flexDirection: "column"}}>
                        <div className="w-100 d-sm-flex d-md-none justify-content-center">
                            <div className="mb-2"
                                style={{background: "linear-gradient( 40deg, #bf68e6 20%, #9e48cd 51%, #bf68e6 90% )"}}>
                                <Link
                                    className="navbar-brand animated d-flex justify-content-center"
                                    data-animation="fadeInDown"
                                    data-animation-delay="1s"
                                    to={"/"}
                                >
                                    <img
                                        src={lock}
                                        alt="CSD Logo"
                                    />
                                </Link>
                            </div>
                        </div>
                        <h6 className="mb-3 text-center">Please enter your login details</h6>
                        <div className="d-flex" style={{flexDirection: "column"}}>
                            <TextField id="email" label="Email address" name="email" variant="standard"
                                       error={!!formErrors.email} helperText={formErrors.email}
                                       value={userLogin.email} onChange={handleInputChange}/>
                            <TextField id="password" label="Password" name="password" variant="standard"
                                       error={!!formErrors.password} helperText={formErrors.password}
                                       value={userLogin.password} type="password" onChange={handleInputChange}/>
                        </div>
                        <div className="d-flex" style={{flexDirection: "column", marginTop: "2rem"}}>
                            <a onClick={props.handleForgotPassword} style={{color: "#1890ff"}}
                               className="card-link blue float-right">Forgot Password?</a>
                        </div>
                        <div className="mt-2">
                            <Button onClick={loginAction} disabled={isLoading}
                                    style={{ color: "white", backgroundColor: "#A14BCF", width: "100%" }}>
                                {isLoading ? <CircularProgress size={24} style={{ color: "white" }} /> : "Login"}
                            </Button>

                            <div className="d-flex mt-2">
                                <span>Don't have an account yet?</span>

                                <a onClick={props.handleRegister} className="ml-1 card-link" style={{color: "#1890ff"}}>
                                    Register
                                </a>
                            </div>
                        </div>
                    </div>


                </Card>

            </Dialog>
        </div>
    )

}

export default LoginDialog;