import { createAction } from '@reduxjs/toolkit';

export interface RecentReports {
    score: number;
    date: number;
    report: string;
    network: string;
    details: string;
}

interface CsdBalance {
    in_wallet: number,
    earnings: number
}

export interface User {
    token: string;
    refresh_token: string;
    first_name: string;
    last_name: string;
    email: string;
    access_level: number;
    access_level_name: string;
    contribution_access_level: number;
    contribution_access_level_name: string;
    csd_balance: CsdBalance,
    reports: {
        reports_per_day: number;
        available: number;
        recent_reports: RecentReports[]

    }
}

export const updateUser = createAction<User>('app/updateUser');

export const addUser = createAction<User>('app/addUser');

export const removeUser = createAction<User>('app/removeUser');