import React, { useEffect } from "react";
import NewsCard from "../NewsCard/NewsCard";
import LatestNewsCard from "../LatestNewsCard/LatestNewsCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchNewsList, setCurrentPage } from "../../state/news/newsSlice";
import Pagination from '@mui/material/Pagination';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory } from "react-router-dom"

import "./style.css"

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const newsData = useSelector((state) => state.news.newsData);
  const totalPages = useSelector((state) => state.news.totalNewsPages);
  const newsDataIsLoading = useSelector((state) => state.news.newsDataIsLoading);
  const latestNews = useSelector((state) => state.news.latestNews);
  const currentPage = useSelector((state) => state.news.currentPage);

  const handlePageChange = (event, value) => {
    dispatch(setCurrentPage(value))
    dispatch(fetchNewsList(value));
  };

  useEffect(() => {
    dispatch(fetchNewsList(currentPage));
  }, [])

  // useEffect(() => {
  //   history.push({
  //     pathname: '/news',
  //     search: `?page=${currentPage}`
  //   })
  // }, [currentPage])

  return (
    <main>
      <section className="blog-area" id="head-area">
        <div className="blog-head" data-midnight="white">
          <div className="blog-head-content">
            <img src="theme-assets/images/csd-news.png" alt="csd news" />
          </div>
        </div>
        <div className="container blog-container">
          <div className="post-listing">
            {
              <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={newsDataIsLoading}
              >
                  <CircularProgress color="inherit" />
              </Backdrop>
            }
            <div className="row">
              <div className="col-md-12 col-lg-8">
                {
                    newsData && newsData['hydra:member'] && newsData['hydra:member'].map((item) => {
                    return (
                        <NewsCard newsItem={item}/>
                    );
                  })
                }

              </div>
              {
                  latestNews && latestNews.length > 0 &&
                  <div className="col-md-12 col-lg-4">
                    <div className="sidebar">
                      <div className="card square">
                        <div className="card-body">
                          <div className="latest-posts">
                            <h4 className="sidebar-title">Latest Posts</h4>
                            <div>
                              {
                                latestNews.map((news) => {
                                  return(
                                      <LatestNewsCard {...news}/>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              }
              <div className="d-flex justify-content-center align-items-center w-100 pb-4 pt-4">
                <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
