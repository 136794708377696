import React from "react";
import ReactHtmlParser from 'react-html-parser';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrentNewsItem } from "../../state/news/newsSlice";

export interface NewsCardProps {
  newsItem: INewsItem
}

interface INewsItem {
  id: number,
  '@id':string,
  title: string,
  content: string,
  summary: string,
  image_url: string,
  posted_date: string,
  news_type: number,
  author: string,
  imageUrl: string,
  postedDate: string,
  newsType: number
}

interface IItem {
  newsItem: INewsItem
}

const NewsCard = ({newsItem}: IItem) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const navigate = () => {
    dispatch(setCurrentNewsItem(null));
    let id = newsItem["@id"].split('/api/get-news/')[1];
    history.push(`/news-detail/${id}`);
  }

    return(
        <div className="card square">
            <img
                className="card-img-top"
                src={newsItem.image_url}
                alt="Blog image area"
            />
            <div className="card-body">
                <div className="post-info">
                    <div className="date-comments">
                        <span className="date">
                          <i className="ti-calendar"> </i>
                          <span>{newsItem.posted_date}</span>
                        </span>
                    </div>
                </div>
                <h2 className="post-title">{newsItem.title}</h2>
                <div className="post-desc">
                    { ReactHtmlParser(newsItem.summary) }
                    <button
                        className="btn read-more float-right btn-glow btn-gradient-blue btn-round mt-4"
                        onClick={() => navigate()}
                    >
                        Read More
                    </button>
                </div>
            </div>
        </div>
    );
}

export default NewsCard;