import React, { useState, useEffect, useCallback } from "react";
import Particles from "react-tsparticles";
import "./style.css";
import {loadFull} from "tsparticles";
import * as api from "../../utils/api-client";
import {useAddUser} from "../../state/user/hooks";
export default () => {
  const addUser = useAddUser();

  useEffect(() => {
    api.post('users/get-user').then((addUserResult) => {
      addUser(addUserResult.data);
    }).catch((err) => {
      // handle error
    })
  }, [])
  const particlesInit = useCallback(async (engine) => {

    // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {

  }, []);


  useEffect(() => {
    const script = document.createElement("script");
    script.src = "theme-assets/js/swiperloader.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <main>
      {/* Header: 3D Animation */}
      <section className="head-area" id="head-area">

      <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
              particles: {
                number: {
                  value: 60,
                  density: {
                    enable: true,
                    value_area: 800,
                  },
                },
                color: {
                  value: "#567bc1",
                },
                shape: {
                  type: "circle",
                  stroke: {
                    width: 0,
                    color: "#000000",
                  },
                  polygon: {
                    nb_sides: 5,
                  },
                  image: {
                    src: "img/github.svg",
                    width: 100,
                    height: 100,
                  },
                },
                opacity: {
                  value: 0.3,
                  random: false,
                  anim: {
                    enable: false,
                    speed: 0.5,
                    opacity_min: 0.1,
                    sync: false,
                  },
                },
                size: {
                  value: 4,
                  random: true,
                  anim: {
                    enable: false,
                    speed: 40,
                    size_min: 0.1,
                    sync: false,
                  },
                },
                line_linked: {
                  enable: true,
                  distance: 150,
                  color: "#567bc1",
                  opacity: 0.4,
                  width: 1,
                },
                move: {
                  enable: true,
                  speed: 3,
                  direction: "none",
                  random: false,
                  straight: false,
                  out_mode: "out",
                  attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 1200,
                  },
                },
              },
              interactivity: {
                detect_on: "canvas",
                events: {
                  onhover: {
                    enable: false,
                    mode: "repulse",
                  },
                  onclick: {
                    enable: false,
                    mode: "push",
                  },
                  resize: true,
                },
                modes: {
                  grab: {
                    distance: 400,
                    line_linked: {
                      opacity: 1,
                    },
                  },
                  bubble: {
                    distance: 400,
                    size: 40,
                    duration: 2,
                    opacity: 8,
                    speed: 3,
                  },
                  repulse: {
                    distance: 200,
                  },
                  push: {
                    particles_nb: 4,
                  },
                  remove: {
                    particles_nb: 2,
                  },
                },
              },
              retina_detect: true,
              config_demo: {
                hide_card: false,
              },
            }}
        />
     
        <div className="head-content d-flex align-items-center">
          <div className="container">
            <div className="banner-wrapper">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12">
                  <div className="banner-content pt-5">
                    <h1
                      className="best-template animated"
                      data-animation="fadeInUpShorter"
                      data-animation-delay="1.5s"
                    >
                      Your investment safeguard <br />

                    </h1>
                    <h3
                      className="d-block white animated"
                      data-animation="fadeInUpShorter"
                      data-animation-delay="1.6s"
                    >
                      Discover the most comprehensive data source and scoring system in the market, meticulously curated by a network of verified experts and a robust community.
                      <br/>Introducing the first-ever decentralized solution designed to shield investors.

                      <br className="d-none d-xl-block" />
                    </h3>
                    <h3
                      className="d-block white animated"
                      data-animation="fadeInUpShorter"
                      data-animation-delay="1.6s"
                    >
                      Join our vibrant ecosystem, where global experts converge, granting you access to dynamic, real-time reports and in-depth analyses. Step into the realm of informed investing with us today.<br />

                      <br className="d-none d-xl-block" />

                    </h3>
                    <div className="play-video text-center">
                      <a
                        href="#"
                        className="play btn btn-lg btn-gradient-purple btn-glow animated fadeInUpShorter video-btn"
                        data-toggle="modal"
                        data-src="https://www.youtube.com/embed/NyDREZUlNvQ"
                        data-target="#ico-modal"
                      >
                        <i className="ti-control-play "> </i>
                        Our mission starts here!
                      </a>
                    </div>

                  </div>
                </div>
                <div className="col-lg-6 col-md-12 move-first">
                  <div className="crypto-video animated" data-animation="fadeInUpShorter" data-animation-delay="1.7s">

                    <div className="mt-5">
                      <img src="/theme-assets/images/svg/data-analysis.svg" className="video-img img-fluid" alt="CSD"></img>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/ Header: 3D Animation */}
      {/* About */}
      <section className="about section-padding" id="about">
        <div className="container">
          <div className="heading text-center">
            <div
              className="animated"
              data-animation="fadeInUpShorter"
              data-animation-delay="0.3s"
            >
              <h6 className="sub-title">About</h6>
              <h2 className="title">What is Crypto Scam Defence ?</h2>
            </div>
            <p
              className="content-desc animated"
              data-animation="fadeInUpShorter"
              data-animation-delay="0.4s"
            >
              The crypto revolution, while bringing groundbreaking opportunities, has also given rise to a concerning trend of fraudulent projects and scams. To date, these deceptive schemes, colloquially known as 'rugs' or 'rug pulls', have led to the loss of millions of dollars, leaving investors in a lurch.
              <br className="d-none d-xl-block" />
              These tactics essentially involve the abrupt withdrawal of support or funds by project creators, akin to metaphorically 'pulling the rug out' from under the investors' feet, leading to sudden and devastating losses.
            </p>
          </div>
          <div className="content-area">
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <h4
                  className="title animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.5s"
                >
                  We have developed  <br className="d-none d-xl-block" />{" "}
                  a cutting-edge platform <br className="d-none d-xl-block" />{" "}
                  tailored for the crypto industry
                </h4>
                <h6
                  className="pt-4 pb-2 animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.6s"
                >
                  aimed at halting the surge of fraudulent projects that have adversely impacted numerous investors.
                </h6>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.7s"
                >
                  The reality of today's market is that actively managing your crypto portfolio demands constant vigilance, often consuming a substantial amount of your time. This brings forth a critical question:
                  <br/>Can one reap the benefits of DeFi without sacrificing excessive time and risking significant financial loss?
                  <br/>Navigating the complexities of DeFi shouldn't equate to a challenging experience in generating profits.
                </p>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.8s"
                >
                  Our solution leverages a blend of advanced technologies to simplify this process, ensuring that you can securely and efficiently engage with DeFi, minimizing both time investment and financial risks.

                </p>

              </div>

              <div
                className="col-md-12 col-lg-6 animated"
                data-animation="fadeInUpShorter"
                data-animation-delay="0.7s"
              >
                <div className="position-relative what-is-crypto-img float-xl-right">
                  <img
                    className="img-fluid"
                    src="theme-assets/images/svg/svg-animation/what-is-csd.svg"
                    alt="What is Crypto?"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/ About */}
      {/* Problems & Solutions */}
      <section
        id="problem-solution"
        className="problem-solution section-pro section-padding "
      >
        <div className="container">
          <div className="heading text-center">
            <div
              className="animated"
              data-animation="fadeInUpShorter"
              data-animation-delay="0.3s"
            >
              <h6 className="sub-title">Solutions</h6>
              <h2 className="title">
                Problems &amp; <strong>Solutions</strong>
              </h2>
            </div>
            <p
              className="content-desc animated"
              data-animation="fadeInUpShorter"
              data-animation-delay="0.4s"
            >
              The biggest risks that investors need to worry about is
              loosing all the money! &nbsp;
              <br className="d-none d-xl-block" />
              ...but this isn't the only thing you should be worried about.
            </p>
          </div>
          <div className="problems">
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="heading mb-4">
                  <h4
                    className="title animated"
                    data-animation="fadeInUpShorter"
                    data-animation-delay="0.2s"
                  >
                    Problems
                  </h4>
                </div>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.4s"
                >
                  In recent years, the landscape of cryptocurrency fraud has been dominated by 'rug pulls' and exit scams, accounting for 99% of all deceptive activities. These types of scams have escalated drastically, representing over 75% of the total volume of hacks and thefts in 2021-2022. This is a significant jump from just 25% in 2020
                </p>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.6s"
                >
                  Both exit scams and rug pulls are notorious forms of crypto fraud. Exit scams typically occur when promoters of a cryptocurrency project vanish with investors' funds, often during or after an Initial Coin Offering (ICO). Rug pulls, a more recent and insidious variant, involve crypto developers abruptly abandoning a project and absconding with the invested capital.
                </p>
              </div>
              <div className="col-md-12 col-lg-6 text-center">
                <img
                  src="theme-assets/images/svg/svg-animation/market-analisys.svg"
                  className="problems-img animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.5s"
                  alt="problems-graphic"
                />
              </div>
            </div>
          </div>
          <div className="solutions mt-5">
            <div className="row">
              <div className="col-md-12 col-lg-6 text-center">
                <img
                  src="theme-assets/images/svg/svg-animation/user-vault.svg"
                  className="solutions-img animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.5s"
                  alt="problems-graphic"
                />
              </div>
              <div className="col-md-12 col-lg-6 move-first">
                <div className="heading mb-4">
                  <h4
                    className="title animated"
                    data-animation="fadeInUpShorter"
                    data-animation-delay="0.2s"
                  >
                    Solutions
                  </h4>
                </div>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.5s"
                >
                  The crypto industry is notoriously challenging, with limited recourse for fund recovery or holding malicious actors accountable. Yet, with the right knowledge, you can significantly reduce the risk of falling prey to scammers.
                </p>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.7s"
                >
                  Leveraging the expertise of our blockchain specialists and advisors, we've compiled crucial data to identify and predict deceptive practices. Our approach began with amalgamating data from various providers, using historical project behaviors to develop sophisticated algorithms and models. These tools perform real-time analysis and assign a reliability score to each new project.
                </p>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.7s"
                >
                  Enhancing this automated process is a secondary layer of manual scrutiny by seasoned experts. We're committed to continuously integrating new tools and features, fostering a diverse network of global experts as part of our ecosystem.
                </p>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.7s"
                >
                  Our goal is to establish the largest network of contributors, intertwining revenue generation with a dual-layered methodology for enhanced validation and reliability.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/ Problems & Solutions */}
      {/* Our Coin */}
      <section id="our-coin" className="our-coin section-padding ">
        <div className="container">
          <div className="heading text-center">
            <div
              className="animated"
              data-animation="fadeInUpShorter"
              data-animation-delay="0.3s"
            >
              <h6 className="sub-title">About coin</h6>
              <h2 className="title">THE TRUE UTILITY OF OUR TOKEN</h2>
            </div>
            <p
              className="content-desc animated"
              data-animation="fadeInUpShorter"
              data-animation-delay="0.4s"
            >
              CSD (Crypto Scam Defence) Token: Your Investment's Guardian.
              <br className="d-none d-xl-block" />
              Leverage the CSD Token to fortify your investments against the unforeseen. By integrating our token into your strategy, you unlock access to the most comprehensive database of insightful reports, real-time evaluation systems, and thorough detection of an array of crypto projects. .{" "}
              <br/>With CSD, you're not just investing; you're empowering your portfolio with cutting-edge defense mechanisms.
            </p>
          </div>
          <div className="row">
            <div
              className="col-lg-5 col-md-12 animated"
              data-animation="fadeInUpShorter"
              data-animation-delay="0.6s"
            >
              <div className="coin-img">
                <img
                  className="img-fluid"
                  src="theme-assets/images/svg/svg-animation/how-it-works.svg"
                  alt="Coin Image"
                />
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="heading mb-4">
                <h4
                  className="title animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.5s"
                >
                  Our mission starts here!
                </h4>
              </div>
              <p
                className="animated"
                data-animation="fadeInUpShorter"
                data-animation-delay="0.6s"
              >
                Our highly motivated professional team found ways that make
                things work differently. Aggregating various technologies we can
                scan, compare with past behaviours, track down each transaction
                made on any network and deep analyse their projects.
              </p>
              <p
                className="animated"
                data-animation="fadeInUpShorter"
                data-animation-delay="0.7s"
              >
                Analyzing the movement of funds we scraped and linked over 2.7
                million transactions across different blockchains and we found
                over 100 000 cases of shifts and come back to original owner.
              </p>
              <p
                className="animated"
                data-animation="fadeInUpShorter"
                data-animation-delay="0.7s"
              >
                We collected and analysed transaction data across eight
                different blockchains to identify how users interacted and we
                developed new heuristics and identified various patterns of
                cross-currency trades.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        id="token-distribution"
        className="token-distribution section-padding"
      >
        <div className="container">
          <div className="heading text-center">
            <div
              className="animated"
              data-animation="fadeInUpShorter"
              data-animation-delay="0.3s"
            >
              <h2 className="title">Token Distribution & Economics</h2>
            </div>
            <p
              className="content-desc animated"
              data-animation="fadeInUpShorter"
              data-animation-delay="0.4s"
            >
              Learning from the history of tokens we know how to set the
              distribution and the economics right from the start.
              <br className="d-none d-xl-block" />
              The best token projects focus on token utility to build network
              effects. We provide a way to grow the network and get many people
              on board with our mission.{" "}
            </p>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-6 pr-5">
              <div className="content-area">
                <h4
                  className="title animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.5s"
                >
                  Rule #1: Business Modeling First, Understanding Our Audience
                </h4>
                <p
                  className="mt-1 animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.6s"
                >
                  Who, What and How
                  <br />
                  We have a deep understanding of our audience, their challenges, and effective solutions. Our business model is centered on aiding a global community, enhancing and forging advanced strategies for the future.
                </p>
                <h4
                  className="title animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.5s"
                >
                  Rule #2: Maximize Tokens for community contributors & Users
                </h4>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.7s"
                >
                  A holder of tokens that contributes to the network is more
                  valuable than a holder of tokens that does not contribute to
                  the network
                </p>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.8s"
                >
                  <span>Symbol:</span>{" "}
                  <strong className="grey-accent2">CSD</strong>
                </p>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.9s"
                >
                  {/*<span>Initial Value:</span>{" "}*/}
                  {/*<strong className="grey-accent2">1 CSD = $0.0000008</strong>*/}
                </p>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="1.0s"
                >
                  <span>Type:</span>{" "}
                  <strong className="grey-accent2">Cross Chain</strong>
                </p>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="1.0s"
                >
                  <span>CSD BSC Contract:</span>{" "}
                  <strong className="grey-accent2">
                    {" "}
                    <a
                      className="white"
                      href={`https://bscscan.com/token/0x67349b4017d55a22b1b666ed696d34fd2edf0c00`}
                      target="_blank"
                    >
                      {'0x67349b4017d55a22b1b666ed696d34fd2edf0c00'}
                    </a>{" "}

                  </strong>
                </p>


                  <p
                      className="animated"
                      data-animation="fadeInUpShorter"
                      data-animation-delay="1.0s"
                  >
                  <span>CSD ETH Contract:</span>{" "}
                  <strong className="grey-accent2">
                    {" "}
                    <a
                      className="white"
                      href={`https://etherscan.io/token/0xba2f318b98218fb7727261defa99017c265bdaef`}
                      target="_blank"
                    >
                      0xba2f318b98218fb7727261defa99017c265bdaef
                    </a>{" "}
                  </strong>
                  </p>

                  <p
                      className="animated"
                      data-animation="fadeInUpShorter"
                      data-animation-delay="1.0s"
                  >
                  <span>CSD MATIC Contract:</span>{" "}
                  <strong className="grey-accent2">
                    {" "}
                    <a
                      className="white"
                      href={`#`}
                    >
                      {'Announcing soon'}
                    </a>{" "}
                  </strong>
                  </p>

                  <p
                      className="animated"
                      data-animation="fadeInUpShorter"
                      data-animation-delay="1.0s"
                  >
                  <span>CSD AVAX Contract:</span>{" "}
                  <strong className="grey-accent2">
                    {" "}
                    <a
                      className="white"
                      href={`#`}
                    >
                      {'Announcing soon'}
                    </a>{" "}
                  </strong>
                  </p>

                  <p
                      className="animated"
                      data-animation="fadeInUpShorter"
                      data-animation-delay="1.0s"
                  >
                  <span>CSD FTM Contract:</span>{" "}
                  <strong className="grey-accent2">
                    {" "}
                    <a
                      className="white"
                      href={`#`}
                    >
                      {'Announcing soon'}
                    </a>{" "}
                  </strong>
                  </p>

                  <p
                      className="animated"
                      data-animation="fadeInUpShorter"
                      data-animation-delay="1.0s"
                  >
                  <span>CSD TRX Contract:</span>{" "}
                  <strong className="grey-accent2">
                    {" "}
                    <a
                      className="white"
                      href={`#`}
                    >
                      {'Announcing soon'}
                    </a>{" "}
                  </strong>
                  </p>

                  <p
                      className="animated"
                      data-animation="fadeInUpShorter"
                      data-animation-delay="1.0s"
                  >
                  <span>CSD ALGO Contract:</span>{" "}
                  <strong className="grey-accent2">
                    {" "}
                    <a
                      className="white"
                      href={`#`}
                    >
                      {'Announcing soon'}
                    </a>{" "}
                  </strong>
                  </p>
                  <p
                      className="animated"
                      data-animation="fadeInUpShorter"
                      data-animation-delay="1.0s"
                  >
                  <span>CSD SOL Contract:</span>{" "}
                  <strong className="grey-accent2">
                    {" "}
                    <a
                      className="white"
                      href={`#`}
                    >
                      {'Announcing soon'}
                    </a>{" "}
                    {/*<p className="text-info small"> ( Announcing soon )*/}
                  </strong>
                  </p>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="1.0s"
                >
                  <span>CSD ITO ETH Contract:</span>{" "}
                  <strong className="grey-accent2">
                    <a
                      className="white"
                      href={`https://etherscan.io/address/0xD895F38552aC9dd76E0926D65c8837a27C97b469`}
                      target="_blank"
                    >
                      0xD895F38552aC9dd76E0926D65c8837a27C97b469
                    </a>
                  </strong>
                </p>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="1.0s"
                >
                  <span>CSD LOCKER Contract:</span>{" "}
                  <strong className="grey-accent2">
                    <a
                      className="white"
                      href={`https://etherscan.io/address/0x2bFc7137e1c9604fea268F83F42a5B7CeB815b83`}
                      target="_blank"
                    >
                      0x2bFc7137e1c9604fea268F83F42a5B7CeB815b83
                    </a>
                  </strong>
                </p>
              </div>
            </div>
            <div
              className="col-md-12 col-lg-6 move-first animated"
              data-animation="fadeInUpShorter"
              data-animation-delay="0.8s"
            >
              <div className="counter-area">
                <div className="counter-content">
                  <div className="banner-wrapper">
                    <div className="banner-content">
                      <div className="clock-counter">
                        <div className="message"></div>
                      </div>
                      <h4
                        className="title animated"
                        data-animation="fadeInUpShorter"
                        data-animation-delay="0.5s"
                      >
                        Total supply over 8 blockchain networks
                      </h4>

                      <p
                        className="mt-1 animated"
                        data-animation="fadeInUpShorter"
                        data-animation-delay="0.6s"
                      >
                        ETH BSC FTM MATIC AVAX ALGO TRX SOL
                        <br />
                        CSD circular flow over network bridge between eight different networks. <br />
                        The inherent utility of the CSD token is a compelling reason for both current and prospective holders to retain it as a key asset.
                        By ensuring continual access to our platform’s advanced real-time solutions, CSD token holders are empowered to safeguard their investments effectively. This practical utility, coupled with the ongoing need for robust investment protection, naturally drives an increase in demand for the CSD token.<br />

                      </p>

                      <h4
                        className="title animated"
                        data-animation="fadeInUpShorter"
                        data-animation-delay="0.5s"
                      >
                        Fully transparent community
                      </h4>

                      <p
                        className="mt-1 animated"
                        data-animation="fadeInUpShorter"
                        data-animation-delay="0.6s"
                      >
                        Worldwide Network of Validated Experts <br />
                        Earn Rewards for Your Contributions <br />

                      </p>

                      <h4
                        className="title animated"
                        data-animation="fadeInUpShorter"
                        data-animation-delay="0.5s"
                      >
                        Initial public offer fulfilled in 23 days
                      </h4>

                      <p
                        className="mt-1 animated"
                        data-animation="fadeInUpShorter"
                        data-animation-delay="0.6s"
                      >
                        18% Distributed to community <br />
                        3% Team <br />
                        9% Locked

                      </p>
                      <p
                        className="mt-1 animated"
                        data-animation="fadeInUpShorter"
                        data-animation-delay="0.6s"
                      >
                        70% Available

                      </p>

                      <h4
                        className="title animated"
                        data-animation="fadeInUpShorter"
                        data-animation-delay="0.5s"
                      >
                        Long-term support and continuous development
                      </h4>

                      <p
                        className="mt-1 animated"
                        data-animation="fadeInUpShorter"
                        data-animation-delay="0.6s"
                      >
                        Due to the business model, CSD will continuously implement
                        new functionalities for the community and with its help, which makes the project have no end.

                      </p>

                      <div className="token-img">
                        <img
                          className="img-fluid"
                          src="/theme-assets/images/svg/success-team.svg"
                          alt="token-distribution"
                        />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* Pre-Sale & Mobile Apps */}
      {/*Exchange Listing Area */}
      <section className="exchange-listing" id="exchange-listing">
        <div className="container-fluid bg-color">
          <div className="container">
            <div className="row listing list-unstyled">
              <div
                className="col d-none d-lg-block text-center animated"
                data-animation="fadeInUpShorter"
                data-animation-delay="0.2s"
              >
                <img
                  src="theme-assets/images/icon-arrow.png"
                  alt="icon-arrow"
                />
                <p className="mt-1">
                  CSD Exchanges <br />
                  more to come..

                </p>
              </div>
              <div
                className="col text-center animated"
                data-animation="fadeInUpShorter"
                data-animation-delay="0.3s"
              >
                <h2></h2>
                <a href={"https://dex-trade.com/"}>
                  <img src="theme-assets/images/dextrade.svg" alt="dex-trade"
                       style={{ position: "absolute",
                                top: '-24px',
                                left: '30px'
                       }}/>
                </a>
              </div>
              <div
                className="col text-center animated"
                data-animation="fadeInUpShorter"
                data-animation-delay="0.4s"
              >
                <h2></h2>
                <a href={"https://coinsbit.io/"}>
                  <img src="theme-assets/images/svg/consbit.svg" alt="coinsbit" />
                </a>
              </div>
              <div
                className="col text-center animated"
                data-animation="fadeInUpShorter"
                data-animation-delay="0.5s"
              >
                <h2></h2>
                <a href={"https://alterdice.com/"}>
                  <img
                    src="theme-assets/images/svg/alterdice.svg"
                    alt="alterdice"
                  />
                </a>
              </div>
              <div
                className="col text-center animated"
                data-animation="fadeInUpShorter"
                data-animation-delay="0.6s"
              >
                <h2></h2>
                <a href={"https://app.uniswap.org/#/swap"}>
                  <img
                    src="theme-assets/images/svg/uniswap.svg"
                    alt="uniswap"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/ Exchange Listing Area */}
      {/* Whitepaper */}
      <section id="whitepaper" className="whitepaper section-padding">
        <div className="container">
          <div className="heading text-center">
            <h6 className="sub-title">Crypto Scam Defence</h6>
            <h2 className="title">Whitepaper</h2>
            <p
              className="content-desc animated"
              data-animation="fadeInUpShorter"
              data-animation-delay="0.4s"
            >
              Starting with “why” means that before getting into the details of
              “what” should be done and “how” it should be done.
              <br className="d-none d-xl-block" />
              What is the higher-order purpose of our work? What are we trying
              to achieve? What is our vision for the future?
            </p>
          </div>
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <div className="whitepaper-img">
                <img
                  className="img-fluid animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.6s"
                  src="theme-assets/images/whitepaper.png"
                  alt="whitepaper"
                />
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="content-area">
                <h4
                  className="title animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.5s"
                >
                  Read Whitepaper
                </h4>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.6s"
                >
                  The purpose of this document is to make you understand the
                  problem we are facing now with the growing popularity of the
                  crypto markets
                </p>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.7s"
                >
                  Cryptocurrencies and blockchain are a monstrosus topic and the
                  applications of blockchain technologies came with a total lack
                  of regulations in order to prevent illegal activities.
                </p>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.8s"
                >
                  Since the legal context is missing in Decentralized Finance
                  built on top of blockchain technology, we are forced to be
                  witnesses of DeFi rising scams.
                </p>
                <p
                  className="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.8s"
                >
                  DeFi rug pulls and exit scams formed 99% of all crypto frauds
                  in 2020. DeFi-related hacks now make up more than 60% of the
                  total hack and theft volume in 2021, a large increase from
                  only 25% in 2020 and 86% in 2023.
                </p>

                <div className="whitepaper-languages">
                  <div className="row">
                    <div
                      className="col-6 col-md-3 text-center mt-4 animated"
                      data-animation="fadeInUpShorter"
                      data-animation-delay="0.6s"
                    >
                      <a
                        href="theme-assets/images/whitepaper.pdf"
                        title="English"
                        target="_blank"
                      >
                        <img
                          src="theme-assets/images/flag-1.png"
                          alt="English"
                        />
                        <div className="lang-text">
                          <span className="icon ti-download mr-1"></span>
                          <span className="language">English</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/ Whitepaper */}

      {/* Roadmap */}
      <section id="roadmap" className="roadmap section-padding">
        <div className="container">
          <div className="heading text-center">
            <div
              className="animated"
              data-animation="fadeInUpShorter"
              data-animation-delay="0.3s"
            >
              <h6 className="sub-title">Implementation</h6>
              <h2 className="title">Roadmap</h2>
            </div>
            <p
              className="content-desc animated"
              data-animation="fadeInUpShorter"
              data-animation-delay="0.4s"
            >
              The journey never ends, this is just the beginning.
              <br className="d-none d-xl-block" />
              Every journey in life starts with a first step, so congratulations
              because you’ve made yours reading this !
            </p>
          </div>
          <div
            className="row animated"
            data-animation="fadeInUpShorter"
            data-animation-delay="0.6s"
          >
            <div className="col-12">
              <div className="roadmap-container">
                <div className="swiper-container">
                  <div className="swiper-wrapper timeline">
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp completed">
                          <span className="date">February 2021</span>
                        </div>
                        <div className="status completed">
                          <span>
                            Founder idea <br />
                            Research & Analyze
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp completed">
                          <span className="date">March 2021</span>
                        </div>
                        <div className="status completed">
                          <span>Proof of Concept</span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp completed">
                          <span className="date">April 2021</span>
                        </div>
                        <div className="status completed">
                          <span>Onboarding & Strategy</span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp completed">
                          <span className="date">May 2021</span>
                        </div>
                        <div className="status completed">
                          <span>
                            FE Beta live
                            <br />
                            BE aggregation started: <br />
                            <a className="white" href="https://market.link/">
                              Market link
                            </a>{" "}
                            <br />
                            <a className="white" href="https://chain.link/">
                              Chain link
                            </a>{" "}
                            <br />
                            <a className="white" href="https://etherscan.io/">
                              Etherscan
                            </a>{" "}
                            <br />
                            <a className="white" href="https://bscscan.com/">
                              BscScan
                            </a>{" "}
                            <br />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp completed">
                          <span className="date">June 2021</span>
                        </div>
                        <div className="status active">
                          <span>Product line up with</span>
                          <span>
                            Board of advisers, investors, partners and
                            development team
                          </span>
                          <span className="live">
                            <br />
                            Smart contracts deployed
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp remaining">
                          <span className="date">July 2021</span>
                        </div>
                        <div className="status remaining">
                          <span>
                            CSD FE V1 live
                            <br />
                          </span>
                          <span>Marketing strategies</span>
                          <span>CSD ITO Smart contracts deployed</span>
                          <span>
                            <br />
                            CSD ITO
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp remaining">
                          <span className="date">September 2021</span>
                        </div>
                        <div className="status remaining">
                          <span>
                            ITO Public Offer <br />{" "}
                          </span>
                          <span>
                            BE MVP live <br />{" "}
                          </span>
                          <span>
                            API Beta launch
                            <br />{" "}
                          </span>
                          <span>
                            {" "}
                            <br />{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp remaining">
                          <span className="date">October 2021</span>
                        </div>
                        <div className="status remaining">
                          <span>
                            Exchange <br />{" "}
                            listings<br />{" "}
                          </span>

                          <span>
                            API V1
                            <br />{" "}
                          </span>
                          <span>
                            {" "}
                            FE/BE/API versions upgrades
                            <br />{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp remaining">
                          <span className="date">November 2021</span>
                        </div>
                        <div className="status remaining">
                          <span>
                            {" "}
                            Dex-Trade IEO <br />
                            CSD Bridge <br />
                            Open source <br />
                            Contributor software development kit
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp remaining">
                          <span className="date">December 2021</span>
                        </div>
                        <div className="status remaining">
                          <span>
                            Dex-Trade Listing <br />
                            Coinsbit Listing<br />
                            Alterdice Listing<br />
                            Uniswap Listing<br />
                          </span>
                          <span>FE/BE/API versions upgrades<br /></span>

                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp remaining">
                          <span className="date">2022 Q1-Q3</span>
                        </div>
                        <div className="status remaining">
                          <span>
                            Development <br />
                            Beta testing<br />
                            Deployment<br />
                          </span>
                          <span>FE/BE/API versions upgrades<br /></span>

                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide active">
                      <div className="roadmap-info">
                        <div className="timestamp remaining">
                          <span className="date">2023 Q4</span>
                        </div>
                        <div className="status remaining">
                          <span>
                            Dashboard MVP release-1.0<br />{" "}
                          </span>
                          <span>
                            ETH data ingestion<br />

                          </span>
                          <span>Marketing</span>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="roadmap-info">
                        <div className="timestamp remaining">
                          <span className="date">2024 Q1</span>
                        </div>
                        <div className="status remaining">
                          <span>
                            Dashboard tools release <br />
                            BSC data ingestion preparation
                            <br />
                          </span>
                          <span>

                          </span>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-control">
                  <span className="prev-slide"></span>
                  <span className="next-slide"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/ Roadmap */}
      {/* ICO Video Modal */}
      <div
        className="modal ico-modal fade"
        id="ico-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body p-0">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe className="embed-responsive-item" id="video"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
