import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit'
import * as api from "../../utils/api-client";

interface IState {
    newsData: any,
    latestNews: any[],
    currentNewsItem: any,
    totalNewsPages: number,
    newsDataIsLoading: boolean,
    newsItemIsLoading: boolean,
    currentPage: number
}

const initialState: IState = {
    newsData: null,
    latestNews: [],
    currentNewsItem: null,
    totalNewsPages: 1,
    newsDataIsLoading: false,
    newsItemIsLoading: false,
    currentPage: 1
}

export const fetchNewsList = createAsyncThunk(
    'news/fetchNewsList',
    async (pageNumber: number, thunkAPI) => {
        return await api.get(`api/get-news?_page=${pageNumber || 1}`);
    }
)

export const getNewsById = createAsyncThunk(
    'news/getNewsById',
    async (id: string, thunkAPI) => {
        return await api.get(`api/get-news/${id}`)
    }
)

export const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        setCurrentPage: (state, action) => {
            return {
                ...state,
                currentPage: action.payload
            }
        },
        setCurrentNewsItem: (state, action) => {
            return {
                ...state,
                currentNewsItem: action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchNewsList.fulfilled, (state, action) => {
            let totalPagesNumber = 1;
            if(action.payload["hydra:view"] && action.payload["hydra:view"]["hydra:last"]) {
                let lastPage = action.payload["hydra:view"]["hydra:last"];
                totalPagesNumber = lastPage.slice(lastPage.indexOf('=') + 1);
            }

            let latestNews = [...current(state["latestNews"])];
            if (totalPagesNumber > 1 && action.payload["hydra:view"]["@id"] === '/api/get-news?_page=1') {
                latestNews = action.payload["hydra:member"].slice(0, 3)
            }

            return {
                ...state,
                newsData: action.payload,
                latestNews,
                totalNewsPages: totalPagesNumber || 1,
                newsDataIsLoading: false
            }
        })
        builder.addCase(fetchNewsList.pending, (state, action) => {
            return {
                ...state,
                newsDataIsLoading: true
            }
        })
        builder.addCase(fetchNewsList.rejected, (state, action) => {
            return {
                ...state,
                newsData: null,
                newsDataIsLoading: false
            }
        })
        builder.addCase(getNewsById.fulfilled, (state, action) => {
            return {
                ...state,
                currentNewsItem: action.payload,
                newsItemIsLoading: false
            }
        })
        builder.addCase(getNewsById.pending, (state, action) => {
            return {
                ...state,
                newsItemIsLoading: true
            }
        })
        builder.addCase(getNewsById.rejected, (state, action) => {
            return {
                ...state,
                currentNewsItem: null,
                newsItemIsLoading: false
            }
        })
    }
})
export const { setCurrentPage, setCurrentNewsItem } = newsSlice.actions;
export default newsSlice.reducer