import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton
} from "react-share";
import { FacebookIcon,
  WhatsappIcon,
  LinkedinIcon,
  RedditIcon,
  TelegramIcon
} from "react-share";
import './SocialShare.scss';

export interface SocialShareProps {
    socials: string[];
    url: string;
}

const SocialShare = ({socials, url}:SocialShareProps) => {

    const socialCollection =
        {
            Facebook: () => {
                return(
                    <FacebookShareButton
                        url={url}
                        hashtag={"#hashtag"}
                        className="share-button Demo__some-network__share-button"
                    >
                        <FacebookIcon size={32} round />
                    </FacebookShareButton>
                );
            },
            Twitter: () => {
                return(
                    <TwitterShareButton
                        title={"test"}
                        url={url}
                        hashtags={["hashtag1", "hashtag2"]}
                        className="share-button"
                    >
                      <img className="mb-2" src={require('../../assets/twitter-logo.png')} alt="" style={{width:27}}/>
                    </TwitterShareButton>
                );
            },
            Whatsapp: () => {
                return(
                    <WhatsappShareButton
                        title={"test"}
                        url={url}
                        className="share-button"
                    >
                        <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                );
            },
            Linkedin: () => {
                return(
                    <LinkedinShareButton
                        title={"test"}
                        url={url}
                        className="share-button"
                    >
                        <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                );
            },
            Reddit: () => {
              return(
                  <RedditShareButton
                      title={"test"}
                      url={url}
                      className="share-button"
                  >
                    <RedditIcon size={32} round />
                  </RedditShareButton>
              );
            },
            Telegram: () => {
              return(
                  <TelegramShareButton
                      title={"test"}
                      url={url}
                      className="share-button"
                  >
                    <TelegramIcon size={32} round />
                  </TelegramShareButton>
              );
            }
        };
    return(
        <div className="social-buttons-container">
            {
                socials.map((item) => {
                    // @ts-ignore
                    return socialCollection[item]();
                })
            }
        </div>
    );
}

export default SocialShare;