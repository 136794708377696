import React from "react";
import {Provider} from "react-redux";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import ModalsProvider from "./contexts/Modals";
import store from "./state";
// import Updaters from "./state/Updaters";
import Popups from "./components/Popups";
import {RefreshContextProvider} from "./contexts/RefreshContext";
import CryptoPreloaderComponent from "./components/CryptoPreloaderComponent";
import CryptoHeaderComponent from "./components/CryptoHeaderComponent";
import CryptoFooterComponent from "./components/CryptoFooterComponent";
import LandingPage from "./pages/LandingPage";
import NewsPage from "./pages/NewsPage";
import NewsDetailPage from "./pages/NewsDetailPage";
import ContactPage from "./pages/ContactPage";
import NewsReadyPage from "./pages/NewsReadyPage";
// import TokenLockPage from "./pages/TokenLockPage";
import "antd/dist/antd.css";
import "./App.css";
import {ToastContainer} from "react-toastify";
import {injectStyle} from "react-toastify/dist/inject-style";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ValidateEmailPage from "./pages/ValidateEmailPage";


const App = () => {
    // Clear localStorage for mobile users
    if (
        typeof localStorage.version_app === "undefined" ||
        localStorage.version_app !== "1.0"
    ) {
        localStorage.clear();
        localStorage.setItem("connectorId", "");
        localStorage.setItem("version_app", "1.0");
    }


    if (typeof window !== "undefined") {
        injectStyle();
    }

    return (
        <Providers>
            <CryptoPreloaderComponent/>
            <Router>
                {/*<CryptoVerticalSocialComponent/>*/}
                <CryptoHeaderComponent/>
                <div className="content-wrapper">
                    <div className="content-body">
                        <Switch>
                            <Route exact path="/" component={LandingPage}/>
                            {/*<Route exact path="/lock" component={TokenLockPage}/>*/}
                            <Route exact path="/news" component={NewsPage}/>
                            <Route exact path="/news-detail/:id" component={NewsDetailPage}/>
                            <Route exact path="/contact" component={ContactPage}/>
                            <Route
                                exact
                                path="/news-ready-to-get-on-board"
                                component={NewsReadyPage}
                            />
                            <Route path="/reset-password/:token" component={ResetPasswordPage} />
                            <Route path="/validate-email" component={ValidateEmailPage} />
                        </Switch>

                    </div>
                </div>

                <CryptoFooterComponent/>
            </Router>
            <ToastContainer autoClose={3500}/>
        </Providers>

    );
};

type ProvidersProps = {
    children: React.ReactNode;
};

const Providers = ({children}:ProvidersProps) => {
    return (
            <Provider store={store}>
                <RefreshContextProvider>
                        <ModalsProvider>
                            <>
                                <Popups/>
                                {children}
                            </>
                        </ModalsProvider>
                </RefreshContextProvider>
            </Provider>
    );
};

export default App;
