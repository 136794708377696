// Generic call to the api
import {environment} from "../environment";

const apiURL = environment.api_url;

// Add here public routes
const noAuthRoutes = [
    "api/get-news",
    apiURL
];

type Response2 = {
    status: {
      code: number,
      message: string
    },
    data: any,
    errors: any[]
}

async function client(
    endpoint: string,
    {body, ...customConfig}: any = {}
): Promise<any> {
    const token: string | null = localStorage.getItem('token');
    const headers: any = {
        "Content-Type": "application/json",
    };

    let noNeedAuth = noAuthRoutes.some((item) => endpoint.includes(item));

    if (token && !noNeedAuth) {
        headers.Authorization = `Bearer ${token}`;
    }
    const config = {
        method: customConfig.method,
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers,

        },
    };
    if (body) {
        config.body = JSON.stringify(body);
    }
    const response: Response | any  = await window.fetch(`${apiURL}/${endpoint}`, config);

    const data = await response.json().then((result: any) => {
        if(response.status && typeof response.status === "object") {
            if((response.status.code === 10006 && response.status.message === "Authentication failed") || (response.errors && response.errors.includes("Token expired!"))) {
                makeRefreshToken();
            }
        }

        if (response.ok) {
            if (result?.errors && result.errors[0] !== null) {
                return Promise.reject(result);
            }
        }
        if (!response.ok) {
            return Promise.reject(result);
        }

        return result;
    }).catch((err:any) => {
        if((err?.status?.code === 10006 && err.status.message === "Authentication failed")) {
            makeRefreshToken();
        }
        return Promise.reject(err);
    });

    if (response.ok) {
        return Promise.resolve(data);
    }
}

const makeRefreshToken = () => {
    const refresh_token: string | null = localStorage.getItem('refresh-token');
    post(`api/token/refresh`, {"refresh_token": refresh_token}).then((response) => {
        localStorage.setItem("token", response.token);
    })
}

export function get(url: any): Promise<any> {
    return client(url, {
        method: "GET",
    });
}

export function post(url: any, data: any): Promise<any> {
    return client(url, {
        method: "POST",
        body: data,
    });
}

export function put(url: any, data: any): Promise<any> {
    return client(url, {
        method: "PUT",
        body: data,
    });
}

export function remove(url: any): Promise<any> {
    return client(`${url}`, {method: "DELETE"});
}

export function patch(url: any, data: any): Promise<any> {
    return client(`${url}`, {method: "PATCH", body: data});
}