import React from "react";
import {useHistory} from "react-router-dom";
import './LatestNewsCard.scss';

export interface LatestNewsCardProps {
    id?: number;
    image_url?: string;
    title?: string;
    slug?: string;
    posted_date?: string;
}

const LatestNewsCard = ({id, image_url, title, slug, posted_date}: LatestNewsCardProps) => {
    const history = useHistory();
    const navigate = () => {
        history.push(`/news-detail/${slug}`);
    }
    return(
        <div className="media latest-news-card" onClick={() => navigate()}>
          <div className="latest-news-item-image-container mr-2">
            <img
                className="latest-news-item-image"
                src={image_url}
                alt="user2"
            />
          </div>

            <div className="media-body">
                <span className="post-name font-weight-bold">
                    {title}
                </span>
                <div className="post-date">
                    {posted_date}
                </div>
            </div>
        </div>
    );
}

export default LatestNewsCard;