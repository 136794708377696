import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { save, load } from 'redux-localstorage-simple';
import transactions from './transactions/reducer';
import application from './application/reducer';
import user from './user/reducer';
import news from './news/newsSlice'

const PERSISTED_KEYS: string[] = ['transactions'];

const rootReducer = combineReducers({
  application: application,
  transactions: transactions,
  user: user,
  news: news
})

const persistConfig = {
  key: "root",
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [
      ...getDefaultMiddleware(),
      ...getDefaultMiddleware({ thunk: false }), save({ states: PERSISTED_KEYS })
  ],
  preloadedState: load({ states: PERSISTED_KEYS }),
});

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
